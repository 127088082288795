import React from "react";

const Time = () => {
  return new Date();
};

export const formatDate = (format: string) => {
  const date = Time();
  let result = format;
  result = result.replace("YYYY", String(date.getFullYear()));
  result = result.replace("YY", String(date.getFullYear()).slice(-2));
  result = result.replace("MM", String(date.getMonth() + 1).padStart(2, "0"));
  result = result.replace("DD", String(date.getDate()).padStart(2, "0"));
  result = result.replace("HH", String(date.getHours()).padStart(2, "0"));
  result = result.replace("hh", date.getHours() < 13 ? String(date.getHours()).padStart(2, "0") : String(date.getHours() - 12).padStart(2, "0"));
  result = result.replace("mm", String(date.getMinutes()).padStart(2, "0"));
  result = result.replace("mm", String(date.getSeconds()).padStart(2, "0"));
  result = result.replace("ampm", date.getHours() < 12 ? "오전" : "오후");
  result = result.replace("w", ["일", "월", "화", "수", "목", "금", "토"][date.getDay()]);
  result = result.replace("W", ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"][date.getDay()]);
  return result;
  // 년-YYYY, YY
  // 월-MM
  // 일-DD
  // 시-HH/hh (HH:24/ hh:12)
  // 분-mm
  // 초-ss
  // 오전오후-ampm
};
export default Time;
