import React from "react";
type Props = {
  sensorDatas: { path: string; title: string; position: string }[];
  selectSensor: number;
  setSelectSensor: React.Dispatch<React.SetStateAction<number>>;
};
const SensorBtnList = ({ sensorDatas, selectSensor, setSelectSensor }: Props) => {
  const handleSensor = (e: any) => {
    setSelectSensor(Number(e.currentTarget.id));
  };

  return (
    <>
      <div className="sensor_btn_list">
        {sensorDatas.map((data, index) => {
          return (
            <div id={String(index)} className={`sensor_btn ${selectSensor === index ? "active" : ""}`} onClick={handleSensor} key={`${data.title}_${data.position}_${index}`}>
              <div className="img_wrap">
                <img id="img" src={data.path} alt={data.title} />
                {selectSensor === index ? <img id="checked" src="imgs/check.png" alt="check" /> : null}
              </div>
              <div className="sensor_text">
                <div className="title">{data.title}</div>
                <div className="position">{data.position}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SensorBtnList;
