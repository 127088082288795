import React, { useEffect, useState } from "react";
import "./Section2.scss";
import WaveSvg from "./WaveSvg";
import CustomGraph from "./CustomGraph";
import useInterval from "../hooks/useInterval";
import getAqman from "../api/aqmanAPI";
import { AqmanType } from "../api/interface/aqman.interface";
import { Link } from "react-router-dom";

type Props = {
  sensorDatas: { path: string; title: string; position: string; serial: string }[];
  selectSensor: number;
};

const Section2 = ({ sensorDatas, selectSensor }: Props) => {
  const [aqman, setAqman] = useState<AqmanType[]>();
  const axiosAqman = async () => {
    const data = await getAqman(["GI28AQ000006", "GI28AQ000010"]);
    if (data !== undefined) setAqman(data);
  };
  const index = () => {
    if (aqman && aqman.length > 0) return aqman.findIndex((data: any) => data.sn === sensorDatas[selectSensor].serial);
    else return 0;
  };
  const data = (name: "aqi" | "aqiGrade" | "co" | "co2" | "co2Grade" | "coGrade" | "humi" | "pm10" | "pm10Grade" | "sn" | "temp" | "tvoc" | "tvocGrade") => {
    try {
      if (aqman) return aqman[index()][name];
      else return "";
    } catch (e: any) {
      console.log("section2 - data()\n", aqman, name, e);
      return "";
    }
  };
  const level = (name: "aqi" | "aqiGrade" | "co" | "co2" | "co2Grade" | "coGrade" | "humi" | "pm10" | "pm10Grade" | "sn" | "temp" | "tvoc" | "tvocGrade") => {
    try {
      if (aqman) {
        const value = aqman[index()][name];
        if (value === "아주좋음" || value === "좋음") return 1;
        else if (value === "보통") return 2;
        else if (value === "나쁨" || value === "매우나쁨") return 3;
        else return 1;
      } else return 1;
    } catch (e: any) {
      console.log("section2 - level()\n", aqman, e);
      return 1;
    }
  };
  const comment = (aqi: number): string => {
    let result = "";
    const commentList = [
      "공기질 상태가 좋습니다. 높아지지 않게 주의하세요!",
      "공기질 상태가 좋습니다. 높아지지 않게 주의하세요!",
      "공기질 상태가 보통입니다. 높아지지 않게 주의하세요!",
      "공기질 개선을 위한 행동요령이 필요합니다.",
      "공기질 개선을 위한 행동요령이 필요합니다.",
    ];
    if (aqi > 80) {
      result = commentList[0];
    } else if (80 >= aqi && aqi > 60) {
      result = commentList[1];
    } else if (60 >= aqi && aqi > 40) {
      result = commentList[2];
    } else if (40 >= aqi && aqi > 20) {
      result = commentList[3];
    } else if (20 >= aqi) {
      result = commentList[4];
    } else {
      result = "Error";
    }
    return result;
  };
  const warningIcon = (aqi: number) => {
    return aqi < 40 ? <img src="imgs/warning.png" alt="warning" /> : null;
  };
  const aqiLevel = (aqi: number): string => {
    let result = "";
    if (aqi > 80) {
      result = "level0";
    } else if (80 >= aqi && aqi > 60) {
      result = "level1";
    } else if (60 >= aqi && aqi > 40) {
      result = "level2";
    } else if (40 >= aqi && aqi > 20) {
      result = "level3";
    } else if (20 >= aqi) {
      result = "level4";
    } else {
      result = "Error";
    }
    return result;
  };
  useEffect(() => {
    axiosAqman();
  }, []);
  useInterval(() => {
    axiosAqman();
  }, 600000);

  return (
    <>
      <section id="section_2">
        <div className="header_wrap">
          <h2>실시간 Indoor Air Quality </h2>
          <div className="is_connect_wrap">
            <span>연결상태</span>
            <div id="circle"></div>
          </div>
          <Link to="/detail" className="btn_link">
            <div className="btn_detail">
              상세보기 <span id="arrow"></span>
            </div>
          </Link>
        </div>
        <div className="info_wrap">
          <div className="text_wrap br_30 bc_w bs_1">
            <div className="name">아키테코 타워</div>
            <div className="address">서울특별시 송파구 문정동</div>
            <div className="position">{sensorDatas[selectSensor].title}</div>
            <img className="office_logo" src="imgs/office_logo.png" alt="office_logo" />
          </div>
          <div className="img_wrap">
            <img src="imgs/office.png" alt="office" />
          </div>
        </div>
        <div className="tips_wrap br_30 bc_w bs_1">
          <div className="title">오늘의 행동요령</div>
          <div className="tips">
            외부환경이 좋으므로 출입문 개방을 통한 자연환기를 최대한 이용하되 환기량과 질이 불충분한 경우 기계환기/ 공기 청정기를 이용해 실내공기질을 개선해주세요. 외부 배기가스, 담배냄새, 공사장 등 오염원의 영향 확인. 화학제품의 사용을 자제 TVOCs 에
            민감한 사람이 없는지 확인해주세요.
          </div>
        </div>
        <div className="sensor_detail_wrap">
          <div className="left">
            <h2>실내 공기질</h2>
            <div className="sensor_wrap">
              <div className="small_card_wrap br_30 bc_w bs_1">
                <div className="data_wrap">
                  <div className="upper">
                    <div className="left">
                      <div className="type">실내공기질</div>
                      <div className="name">온도</div>
                    </div>
                    <div className="right">
                      <img src="imgs/temp.png" alt="temp" />
                    </div>
                  </div>
                  <div className="under">
                    {data("temp")}˚<span className="unit"></span>
                  </div>
                </div>
              </div>
              <div className="small_card_wrap br_30 bc_w bs_1">
                <div className="data_wrap">
                  <div className="upper">
                    <div className="left">
                      <div className="type">실내공기질</div>
                      <div className="name">습도</div>
                    </div>
                    <div className="right">
                      <img src="imgs/humi.png" alt="humi" />
                    </div>
                  </div>
                  <div className="under">
                    {data("humi")}
                    <span className="unit"> %</span>
                  </div>
                </div>
              </div>
              <div className="card_wrap br_30 bc_w bs_1">
                <div className="data_wrap">
                  <div className="upper">
                    <div className="left">
                      <div className="type">실내공기질</div>
                      <div className="name">미세먼지</div>
                    </div>
                    <div className="right">{data("pm10Grade")}</div>
                  </div>
                  <div className="under">
                    {data("pm10")} <span className="unit">㎍/㎥</span>
                  </div>
                </div>
                <WaveSvg width={235} height={173} level={level("pm10Grade")} />
              </div>
              <div className="card_wrap br_30 bc_w bs_1">
                <div className="data_wrap">
                  <div className="upper">
                    <div className="left">
                      <div className="type">실내공기질</div>
                      <div className="name">CO2</div>
                    </div>
                    <div className="right">{data("co2Grade")}</div>
                  </div>
                  <div className="under">
                    {data("co2")}
                    <span className="unit">PPM</span>
                  </div>
                </div>
                <WaveSvg width={235} height={173} level={level("co2Grade")} />
              </div>
              <div className="card_wrap br_30 bc_w bs_1">
                <div className="data_wrap">
                  <div className="upper">
                    <div className="left">
                      <div className="type">실내공기질</div>
                      <div className="name">TVOC</div>
                    </div>
                    <div className="right">{data("tvocGrade")}</div>
                  </div>
                  <div className="under">
                    {data("tvoc")}
                    <span className="unit">PPB</span>
                  </div>
                </div>
                <WaveSvg width={235} height={173} level={level("tvocGrade")} />
              </div>
              <div className="card_wrap br_30 bc_w bs_1">
                <div className="data_wrap">
                  <div className="upper">
                    <div className="left">
                      <div className="type">실내공기질</div>
                      <div className="name">CO</div>
                    </div>
                    <div className="right">{data("coGrade")}</div>
                  </div>
                  <div className="under">
                    {data("co")}
                    <span className="unit">PPM</span>
                  </div>
                </div>
                <WaveSvg width={235} height={173} level={level("coGrade")} />
              </div>
            </div>
          </div>
          <div className="right">
            <div className={`total_wrap br_30 bs_1 ${aqiLevel(Number(data("aqi")))}`}>
              <div className="upper">
                <div className="left">
                  <div className="type">통합 그래프</div>
                  <div className="name">통합 공기질 상태</div>
                  <div className="comment">{comment(Number(data("aqi")))}</div>
                </div>
                <div className="right">{warningIcon(Number(data("aqi")))}</div>
              </div>
              <div className="middle">
                <div className="graph">
                  <CustomGraph width={350} height={200} option={{ cx: 170, cy: 160, iR: 120, oR: 160 }} percentage={Number(data("aqi"))} color="#ffffff" />
                </div>
                <div className="ment">{data("aqiGrade")}</div>
                <div className="label label_1">0%</div>
                <div className="label label_2">50%</div>
                <div className="label label_3">100%</div>
              </div>
              <div className="under">
                <div className="percentage">{data("aqi")} %</div>
                <div className="detail">
                  미세먼지 <span className={aqiLevel(Number(data("aqi")))}>{data("pm10Grade")}</span>이산화탄소<span className={aqiLevel(Number(data("aqi")))}>{data("co2Grade")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section2;
