import React from "react";
import "./Section1.scss";
import SensorBtnList from "./SensorBtnList";

type Props = {
  sensorDatas: { path: string; title: string; position: string; serial: string }[];
  selectSensor: number;
  setSelectSensor: React.Dispatch<React.SetStateAction<number>>;
};
const Section1 = ({ sensorDatas, selectSensor, setSelectSensor }: Props) => {
  return (
    <>
      <section id="section_1">
        <SensorBtnList sensorDatas={sensorDatas} selectSensor={selectSensor} setSelectSensor={setSelectSensor} />
        <div className="logo_wrap">
          <img src="imgs/zeroed_logo.png" alt="logo" />
        </div>
      </section>
    </>
  );
};

export default Section1;
