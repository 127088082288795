import React, { useEffect, useState } from "react";
import "./Section3.scss";
import { formatDate } from "../utils/Time";
import CustomGraph from "./CustomGraph";
import useInterval from "../hooks/useInterval";
import { WeatherInit, WeatherType } from "../api/interface/weather.interface";

type Props = {
  weatherData: WeatherType;
};
const Section3 = ({ weatherData }: Props) => {
  const [ampm, setAmpm] = useState(formatDate("ampm"));
  const [time, setTime] = useState(formatDate("hh:mm"));
  const [date, setDate] = useState(formatDate("YYYY.MM.DD W"));
  const [weather, setWeather] = useState<WeatherType>(WeatherInit);
  const grade2Text: { [key: string]: string } = { "1": "좋음", "2": "보통", "3": "나쁨", "4": "매우나쁨" };
  const skyState = (icon: boolean) => {
    const skyArray: { [key: string]: string } = { "1": "맑음", "3": "구름많음", "4": "흐림" };
    const skyIconArray: { [key: string]: string } = { "1": "weather_sky_icon_01.png", "3": "weather_sky_icon_03.png", "4": "weather_sky_icon_04.png" };
    const ptyArray: { [key: string]: string } = { "1": "비", "2": "비/눈", "3": "눈", "5": "빗방울", "6": "빗방울눈날림", "7": "눈날림" };
    const ptyIconArray: { [key: string]: string } = {
      "1": "weather_pty_icon_01.png",
      "2": "weather_pty_icon_02.png",
      "3": "weather_pty_icon_03.png",
      "5": "weather_pty_icon_05.png",
      "6": "weather_pty_icon_06.png",
      "7": "weather_pty_icon_07.png",
    };
    if (weather.pty === "0") {
      return icon ? skyIconArray[weather.sky] : skyArray[weather.sky];
    } else {
      return icon ? ptyIconArray[weather.pty] : ptyArray[weather.pty];
    }
  };
  useInterval(() => {
    setAmpm(formatDate("ampm"));
    setTime(formatDate("hh:mm"));
    setDate(formatDate("YYYY.MM.DD W"));
  }, 1000);

  useEffect(() => {
    setWeather(weatherData);
  }, [weatherData]);

  return (
    <>
      <section id="section_3">
        <div className="outdoor_data_warp">
          <div className="header">
            <h2>오늘의 날씨</h2>
            <div className="time_warp">
              <span className="ampm">{ampm}</span>
              <span className="time">{time}</span>
            </div>
          </div>
          <div className="weather_temp_wrap br_30 bc_w">
            <div className="weather_wrap">
              <img className="img" src={`imgs/weather/${skyState(true)}`} alt="" />
              <span className="text">{skyState(false)}</span>
            </div>
            <div className="temp_wrap">
              <span className="temp">{weather.temp}˚</span>
              <span>현재온도</span>
            </div>
          </div>
          <div className="position_date_wrap br_30 bc_w">
            <div className="position_wrap">
              <img src="imgs/marker.png" alt="marker" />
              <span className="position">서울특별시 송파구 문정동</span>
            </div>
            <div className="date_wrap">
              <span className="date">{date}</span>
            </div>
          </div>
          <div className="outdoor_sensor_wrap">
            {}
            <div className="sensor_wrap br_30 bc_w">
              <div className="upper">
                <span>실외공기질</span>
                <span>PM10</span>
              </div>
              <div className={`middle level_${weather.air.pm10Grade}`}>
                <span className="value">{weather.air.pm10Value}</span>
                <span className="grade">{grade2Text[weather.air.pm10Grade]}</span>
              </div>
              <div className="under">
                <span className="name">미세먼지</span>
                <div className="unit">㎍/㎥</div>
              </div>
            </div>
            <div className="sensor_wrap br_30 bc_w">
              <div className="upper">
                <span>실외공기질</span>
                <span>PM25</span>
              </div>
              <div className={`middle level_${weather.air.pm25Grade}`}>
                <span className="value">{weather.air.pm25Value}</span>
                <span className="grade">{grade2Text[weather.air.pm25Grade]}</span>
              </div>
              <div className="under">
                <span className="name">초미세먼지</span>
                <div className="unit">㎍/㎥</div>
              </div>
            </div>
            <div className="sensor_wrap br_30 bc_w">
              <div className="upper">
                <span>실외공기질</span>
                <span>CO</span>
              </div>
              <div className={`middle level_${weather.air.coGrade}`}>
                <span className="value">{weather.air.coValue}</span>
                <span className="grade">{grade2Text[weather.air.coGrade]}</span>
              </div>
              <div className="under">
                <span className="name">일산화탄소</span>
                <div className="unit">PPM</div>
              </div>
            </div>
            <div className="sensor_wrap br_30 bc_w">
              <div className="upper">
                <span>실외공기질</span>
                <span>CO3</span>
              </div>
              <div className={`middle level_${weather.air.o3Grade}`}>
                <span className="value">{weather.air.o3Value}</span>
                <span className="grade">{grade2Text[weather.air.o3Grade]}</span>
              </div>
              <div className="under">
                <span className="name">오존</span>
                <div className="unit">DU</div>
              </div>
            </div>
          </div>
        </div>
        <div className="building_monitoring_wrap">
          <h2>환기 동작 상태 / 에너지 사용량</h2>
          <div className="card_wrap">
            <div className="fan_wrap br_30 bc_w bs_1">
              <div className="upper">
                <span className="name">환기풍량/동작상태</span>
                <div className="status">
                  <span>작동중</span>
                  <div id="circle"></div>
                </div>
              </div>
              <div className="under">
                <div className="level">1단</div>
                <img src="imgs/fan.png" alt="pan" />
              </div>
            </div>
            <div className="energy_wrap br_30 bc_w bs_1">
              <div className="upper">
                <div className="name">에너지 사용량</div>
                <div className="value">35.5</div>
              </div>
              <div className="under">
                <div className="graph">
                  <CustomGraph width={150} height={80} option={{ cx: 70, cy: 70, iR: 50, oR: 70 }} percentage={35} color="#2f5427" neddleColor="#dc0000" />
                </div>
                <div className="level">보통</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
