export type WeatherType = {
  temp: any;
  sky: any;
  pty: any;
  air: {
    pm10Value: string;
    pm10Grade: string;
    pm25Value: string;
    pm25Grade: string;
    coValue: string;
    coGrade: string;
    o3Value: string;
    o3Grade: string;
  };
};
export const WeatherInit = {
  temp: "0",
  sky: "0",
  pty: "0",
  air: {
    pm10Value: "0",
    pm10Grade: "0",
    pm25Value: "0",
    pm25Grade: "0",
    coValue: "0",
    coGrade: "0",
    o3Value: "0",
    o3Grade: "0",
  },
};
