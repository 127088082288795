import axios from "axios";
import type { AqmanLogType, AqmanType } from "./interface/aqman.interface";
let token = "";
let preData: AqmanType[] | undefined = undefined;
let preLog: AqmanLogType[] | undefined = undefined;
const getAqman = async (serial: string[]): Promise<AqmanType[] | undefined> => {
  try {
    return await getDatas(serial);
  } catch (e: any) {
    try {
      token = await getToken();
      return await getDatas(serial);
    } catch (e: any) {
      return preData;
    }
  }
};
type getLogData = { sn: string; from: string; to: string };
const getLogData = async ({ sn, from, to }: getLogData): Promise<AqmanLogType[] | undefined> => {
  try {
    return await getLog({ sn, from, to });
  } catch (e: any) {
    try {
      token = await getToken();
      return await getLog({ sn, from, to });
    } catch (e: any) {
      return preLog;
    }
  }
};
const getToken = async (): Promise<string> => {
  try {
    const id = "kict";
    const password = "admin";
    const token = await axios.post("https://ftlab-vm-api.com/api/rsv2/customers/login", { id, password });
    return token.data.tokenWeb;
  } catch (e: any) {
    return "";
  }
};

const getDatas = async (serial: string[]): Promise<AqmanType[] | undefined> => {
  try {
    const response = serial.map((serial: string) => {
      const data = axiosInstance.get(`https://radoneyestationv2-api.azurewebsites.net/api/devices?sn=${serial}`, { headers: { Token: token } });
      return data;
    });
    const responseArray = await Promise.all(response);
    const result = responseArray.map((response: any) => {
      const result = {
        sn: response.data.sn,
        aqi: aqiValue(response.data),
        aqiGrade: gradeAqi(aqiValue(response.data)),
        pm10: response.data.pm10,
        pm10Grade: grade("pm10", response.data.pm10),
        co: response.data.co,
        coGrade: grade("co", response.data.co),
        co2: response.data.co2,
        co2Grade: grade("co2", response.data.co),
        tvoc: response.data.tvoc,
        tvocGrade: grade("tvoc", response.data.tvoc),
        temp: response.data.temp,
        humi: response.data.humi,
      };
      preData = [];
      preData?.push(result);
      return result;
    });
    return result;
  } catch (e: any) {
    throw e;
  }
};
const grade = (name: string, value: number): string => {
  const gradeMap: any = {
    pm10: [15, 35, 75, 200],
    pm25: [10, 20, 35, 75],
    co2: [350, 600, 1000, 2000],
    hcho: [30, 50, 100, 400],
    co: [2, 5, 10, 25],
    tvoc: [100, 200, 400, 800],
    aqi: [80, 60, 40, 20],
  };
  const map = gradeMap[name];
  let result = "";
  if (value < map[0]) {
    result = "아주좋음";
  } else if (map[0] <= value && value < map[1]) {
    result = "좋음";
  } else if (map[1] <= value && value < map[2]) {
    result = "보통";
  } else if (map[2] <= value && value < map[3]) {
    result = "나쁨";
  } else if (map[3] <= value) {
    result = "매우나쁨";
  } else {
    result = "Error";
  }
  return result;
};
const gradeAqi = (value: number): string => {
  const map = [80, 60, 40, 20];
  let result = "";
  if (value > map[0]) {
    result = "아주좋음";
  } else if (map[0] >= value && value > map[1]) {
    result = "좋음";
  } else if (map[1] >= value && value > map[2]) {
    result = "보통";
  } else if (map[2] >= value && value > map[3]) {
    result = "나쁨";
  } else if (map[3] >= value) {
    result = "매우나쁨";
  } else {
    result = "Error";
  }
  return result;
};
const aqiValue = (data: any) => {
  const date = new Date();
  const month = date.getMonth() + 1;
  // 계산을 위한 설정값 정의 ( 여름,겨울 구분하여 값이 다름 ) _ 5월~10월 = 여름
  let temp_max: number, temp_min: number, humi_max: number, humi_min: number;
  if (5 <= month && month <= 10) {
    [temp_max, temp_min, humi_max, humi_min] = [30, 20, 100, 50];
  } else {
    [temp_max, temp_min, humi_max, humi_min] = [22, 10, 55, 0];
  }
  console.log([temp_max, temp_min, humi_max, humi_min]);
  let okCount = 0;
  if (Number(data.temp) !== 0) okCount += 1;
  if (Number(data.humi) !== 0) okCount += 1;
  const calc_temp = (100 - 100 * (Math.abs(data.temp - temp_max) / (temp_max - temp_min))) * 1;
  const calc_humi = (100 - 100 * (Math.abs(data.humi - humi_max) / (humi_max - humi_min))) * 1;
  let I = Math.round((calc_temp + calc_humi) / okCount);
  if (!isFinite(I)) I = 0;
  return I;
};

const getLog = async ({ sn, from, to }: getLogData): Promise<AqmanLogType[] | undefined> => {
  try {
    const data = await axios.get(`https://radoneyestationv2-api.azurewebsites.net/api/logs?devicesn=${sn}&from=${from}&to=${to}`, { headers: { Token: token } });
    const result: AqmanLogType[] = data.data;
    return result;
  } catch (e: any) {
    throw e;
  }
};

const axiosInstance = axios.create({
  baseURL: "", // 기본 URL
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});
// request 인터셉터를 설정하여 요청을 보내기 전에 토큰을 추가
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken"); // 로컬 스토리지에서 accessToken을 가져옴
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // Authorization 헤더에 토큰을 추가
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default getAqman;
export { getLogData };
