import React, { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, TooltipProps } from "recharts";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";
type Props = {
  width: number;
  height: number;
  name: string;
  unit: string;
  data?: any;
  checked: boolean[];
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>): JSX.Element => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="time">{label}</p>
        <div className="value_wrap">
          {payload.length > 0 ? (
            payload.map((value: any, index: number) => {
              return (
                <div className="value" key={`${value}_${index}`}>
                  <div className="name_wrap">
                    <div className="ball" style={{ backgroundColor: value.color }}></div>
                    <span>{value.name.substring(0, value.name.indexOf("_", 0))}</span>
                  </div>
                  <span>{value.value}</span>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  return <></>;
};
const CustomLineGraph = ({ width, height, name, unit, data, checked }: Props) => {
  const [graphDate, setGraphData] = useState([]);
  const label: any = {
    PM1: "pm1",
    PM10: "pm10",
    "PM2.5": "pm2d5",
    온도: "temp",
    습도: "humi",
    라돈: "radon",
    CO: "co",
    CO2: "co2",
    TVOC: "tvoc",
    HCHO: "hcho",
  };
  useEffect(() => {
    if (data) setGraphData(data);
  }, [data]);
  return (
    <>
      <div className="custom_line_graph_wrap" style={{ width: width, height: height, borderRadius: 30 }}>
        <div className="info_wrap">
          <div className="name">{name}</div>
          <div className="unit">{unit}</div>
        </div>
        <div className="graph_wrap">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={graphDate} margin={{ top: 10, right: 10, bottom: 30 }}>
              <CartesianGrid stroke="#d9d9d9" />
              <XAxis axisLine={false} tickLine={false} dataKey="dt" fontSize={12} hide />
              <YAxis axisLine={false} tickLine={false} tickSize={5} width={30} />
              <Tooltip content={<CustomTooltip />} cursor={{ stroke: "#111111", strokeDasharray: 10, strokeWidth: 1.5 }} />
              {checked[0] ? <Line dataKey={`GI28AQ000006_${label[name]}`} stroke="#FF9700" strokeWidth={2} dot={false} /> : null}
              {checked[1] ? <Line dataKey={`GI28AQ000010_${label[name]}`} stroke="#32a1ff" strokeWidth={2} dot={false} /> : null}
              {/* <Line dataKey="GI28AQ000006" stroke="#FF9700" strokeWidth={2} dot={false} />
              <Line dataKey="GI28AQ000010" stroke="#32a1ff" strokeWidth={2} dot={false} /> */}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default CustomLineGraph;
