import React, { useEffect, useState } from "react";
import "./Home.scss";
import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import getWeather from "../api/weatherAPI";
import useInterval from "../hooks/useInterval";
import { WeatherInit, WeatherType } from "../api/interface/weather.interface";

const Home = () => {
  const [selectSensor, setSelectSensor] = useState(0);
  const [weatherData, setWeatherData] = useState<WeatherType>(WeatherInit);
  const sensorDatas = [
    { path: "imgs/lab.png", title: "연구소", position: "11F", serial: "GI28AQ000006" },
    { path: "imgs/of.png", title: "사무실", position: "11F", serial: "GI28AQ000010" },
  ];
  const getWeathers = async () => {
    const data = await getWeather();
    setWeatherData(data);
  };

  useEffect(() => {
    getWeathers();
  }, []);
  useInterval(() => {
    getWeathers();
  }, 600000);
  return (
    <>
      <div className="home">
        <Section1 sensorDatas={sensorDatas} selectSensor={selectSensor} setSelectSensor={setSelectSensor} />
        <Section2 sensorDatas={sensorDatas} selectSensor={selectSensor} />
        <Section3 weatherData={weatherData} />
      </div>
    </>
  );
};

export default Home;
