import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { ko } from "date-fns/locale";
import { addDays } from "date-fns";
type Props = {
  dateRange: any[];
  setDateRange: React.Dispatch<React.SetStateAction<any[]>>;
};
const CustomDatePicker = ({ dateRange, setDateRange }: Props) => {
  // const [dateRange, setDateRange] = useState([undefined, undefined]);
  const [preDateRange, setPreDateRange] = useState([undefined, undefined]);
  const [startDate, endDate] = dateRange;
  const [selecting, setSelecting] = useState(false);
  const CustomInput = forwardRef(({ value, onClick, className }: any, ref: any) => (
    <button className={className} onClick={onClick} ref={ref}>
      {value}
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="white" />
        <path
          d="M18.9844 22.993H24V28.0047H18.9844V22.993ZM30.9844 31.9859V21.0258H17.0156V31.9859H30.9844ZM30.9844 16.0141C31.5156 16.0141 31.9844 16.217 32.3906 16.623C32.7969 17.0289 33 17.4973 33 18.0281V31.9859C33 32.5168 32.7969 32.9852 32.3906 33.3911C31.9844 33.797 31.5156 34 30.9844 34H17.0156C16.4531 34 15.9688 33.797 15.5625 33.3911C15.1875 32.9852 15 32.5168 15 31.9859V18.0281C15 17.4973 15.1875 17.0289 15.5625 16.623C15.9688 16.217 16.4531 16.0141 17.0156 16.0141H18V14H20.0156V16.0141H27.9844V14H30V16.0141H30.9844Z"
          fill="#2F5427"
        />
      </svg>
    </button>
  ));
  console.log(startDate, endDate);
  return (
    <>
      <DatePicker
        range
        swapRange
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        locale={ko}
        showPopperArrow={false}
        maxDate={dateRange[0] ? addDays(dateRange[0], 30) : new Date()}
        // maxDate={selecting ? new Date() : addDays(startDate, 5)}
        minDate={startDate}
        dateFormat="yyyy년 MM월 dd일"
        // dateFormatCalendar="YYYY년 MMMM"
        // placeholderText="날짜를 선택해주세요"
        calendarClassName="calender_wrap"
        customInput={<CustomInput className="custom_input_wrap" />}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
          <div className="calender_header_wrap">
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <div className="date_wrap">{`${date.getFullYear()}년 ${date.getMonth() + 1}월`}</div>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        onChange={(update: any) => {
          console.log("onChange");
          setDateRange(update);
        }}
        onCalendarOpen={() => {
          console.log("onCalendarOpen");
          setPreDateRange(dateRange);
          setDateRange([undefined, undefined]);
          setSelecting(false);
        }}
        onClickOutside={() => {
          console.log("onClickOutside");
          setDateRange(preDateRange);
          setSelecting(false);
        }}
        onSelect={() => {
          console.log("onselect");
          setSelecting(true);
        }}
      />
    </>
  );
};

export default CustomDatePicker;
