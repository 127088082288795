import React, { useEffect, useState } from "react";
import "./WaveSvg.scss";

type Props = {
  width: number;
  height: number;
  level: number;
};
const WaveSvg = ({ width, height, level }: Props) => {
  const [color, setColor] = useState("");
  let color2 = "";
  useEffect(() => {
    if (level) {
      setColor(level === 1 ? "113,217,91" : level === 2 ? "50,161,255" : level === 3 ? "255,89,89" : "");
    }
  }, [level]);

  return (
    <>
      <svg style={{ width: width, height: height }} viewBox="0 0 1 1" preserveAspectRatio="none" className={`${level === 1 ? "level_1" : level === 2 ? "level_2" : level === 3 ? "level_3" : ""}`}>
        <defs>
          <linearGradient id="wave_1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(255,255,255,0.1)" />
            <stop offset="50%" stopColor="rgba(113,217,91,0.1)" />
            <stop offset="100%" stopColor="rgba(255,255,255,0.1)" />
          </linearGradient>
          <linearGradient id="line_1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="rgba(255,255,255,1)" />
            <stop offset="50%" stopColor="rgba(113,217,91,1)" />
            <stop offset="100%" stopColor="rgba(255,255,255,1)" />
          </linearGradient>
          <linearGradient id="wave_2" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(255,255,255,0.1)" />
            <stop offset="50%" stopColor="rgba(50,161,255,0.1)" />
            <stop offset="100%" stopColor="rgba(255,255,255,0.1)" />
          </linearGradient>
          <linearGradient id="line_2" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="rgba(255,255,255,1)" />
            <stop offset="50%" stopColor="rgba(50,161,255,1)" />
            <stop offset="100%" stopColor="rgba(255,255,255,1)" />
          </linearGradient>
          <linearGradient id="wave_3" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(255,255,255,0.1)" />
            <stop offset="50%" stopColor="rgba(255,89,89,0.1)" />
            <stop offset="100%" stopColor="rgba(255,255,255,0.1)" />
          </linearGradient>
          <linearGradient id="line_3" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="rgba(255,255,255,1)" />
            <stop offset="50%" stopColor="rgba(255,89,89,1)" />
            <stop offset="100%" stopColor="rgba(255,255,255,1)" />
          </linearGradient>
        </defs>
        <path id="wave" fill={`url(#${level === 1 ? "wave_1" : level === 2 ? "wave_2" : "wave_3"})`} />
        <path id="line" fill="none" stroke={`url(#${level === 1 ? "line_1" : level === 2 ? "line_2" : "line_3"})`} strokeWidth={0.01} />
      </svg>
    </>
  );
};

export default WaveSvg;
