import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";

type Props = {
  width: number;
  height: number;
  option: { cx: number; cy: number; iR: number; oR: number };
  percentage: number;
  color: string;
  neddleColor?: string;
};
const CustomGraph = ({ width, height, option, percentage, color, neddleColor }: Props) => {
  const [data, setData] = useState<{ name: string; value: number; color: string }[]>();
  const RADIAN = Math.PI / 180;
  const cx = option.cx;
  const cy = option.cy;
  const iR = option.iR;
  const oR = option.oR;
  const value = percentage * 3;

  useEffect(() => {
    const newData: any = [];
    for (var i = 0; i < 30; i++) {
      if (percentage / 3.3333 > i) {
        newData.push({ name: "A", value: 10, color: color });
      } else {
        newData.push({ name: "B", value: 10, color: "rgba(0, 0, 0, 0.2)" });
      }
    }
    setData(newData);
  }, [percentage]);

  const needle = (value: any, data: any, cx: any, cy: any, iR: any, oR: any, color: any) => {
    let total = 0;
    data.forEach((v: any) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 5;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [<circle cx={x0} cy={y0} r={r} fill={color} stroke="none" key={`${value}+1`} />, <path key={value} d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />];
  };
  return (
    <>
      <div className="test">
        <PieChart width={width} height={height}>
          <Pie dataKey="value" startAngle={180} endAngle={0} data={data} cx={cx} cy={cy} innerRadius={iR} outerRadius={oR} paddingAngle={3}>
            {data !== undefined ? data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} stroke={"none"} />) : null}
          </Pie>
          {data !== undefined ? needle(value, data, cx, cy, iR, oR, neddleColor ? neddleColor : color) : null}
        </PieChart>
      </div>
    </>
  );
};

export default CustomGraph;
