import axios from "axios";
const WEATHER_KEY = "k00ub%2BYmWalYhB6fBvapmzUS1%2BMxInYTikeGwleUqAw9sg0%2F9Kw4nJmElNTR7Q4wdtdHTPHW57gPfeWork8wZw%3D%3D";
const WEATHER_NX = 62;
const WEATHER_NY = 125;
let preTemp = "",
  prePty = "",
  preSky = "",
  preAir = {
    pm10Value: "",
    pm10Grade: "",
    pm25Value: "",
    pm25Grade: "",
    coValue: "",
    coGrade: "",
    o3Value: "",
    o3Grade: "",
  };

const getWeather = async () => {
  try {
    const temp = await getTemp();
    const { sky, pty } = await getSkyPty();
    const air = await getAir();
    return { temp, sky, pty, air };
  } catch (e) {
    console.log(e);
    const temp = preTemp;
    const sky = preSky;
    const pty = prePty;
    const air = preAir;
    return { temp, sky, pty, air };
  }
};

const getAir = async () => {
  try {
    const url = `http://apis.data.go.kr/B552584/ArpltnInforInqireSvc/getMsrstnAcctoRltmMesureDnsty?serviceKey=${WEATHER_KEY}&pageNo=1&numOfRows=1&returnType=JSON&stationName=송파구&dataTerm=DAILY&ver=1.0`;
    const result = await axios.get(url);
    const air = {
      pm10Value: "",
      pm10Grade: "",
      pm25Value: "",
      pm25Grade: "",
      coValue: "",
      coGrade: "",
      o3Value: "",
      o3Grade: "",
    };
    const item = result.data.response.body.items[0];
    air.pm10Value = item.pm10Value === "-" || item.pm10Value === null ? preAir.pm10Value : item.pm10Value;
    air.pm10Grade = item.pm10Grade === "-" || item.pm10Grade === null ? preAir.pm10Grade : item.pm10Grade;
    air.pm25Value = item.pm25Value === "-" || item.pm25Value === null ? preAir.pm25Value : item.pm25Value;
    air.pm25Grade = item.pm25Grade === "-" || item.pm25Grade === null ? preAir.pm25Grade : item.pm25Grade;
    air.coGrade = item.coGrade === "-" || item.coGrade === null ? preAir.coGrade : item.coGrade;
    air.coValue = item.coValue === "-" || item.coValue === null ? preAir.coValue : item.coValue;
    air.o3Grade = item.o3Grade === "-" || item.o3Grade === null ? preAir.o3Grade : item.o3Grade;
    air.o3Value = item.o3Value === "-" || item.o3Value === null ? preAir.o3Value : item.o3Value;
    preAir = air;
    return air;
  } catch (e) {
    console.log(e);
    return preAir;
  }
};

const getSkyPty = async () => {
  try {
    const { baseDate, baseTime } = getDateTime("30");
    const url = `http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getUltraSrtFcst?serviceKey=${WEATHER_KEY}&pageNo=1&numOfRows=60&dataType=JSON&base_date=${baseDate}&base_time=${baseTime}&nx=${WEATHER_NX}&ny=${WEATHER_NY}`;
    const result = await axios.get(url);
    const items = result.data.response.body.items.item;
    const skys = items.filter((item: any) => item.category === "SKY");
    const ptys = items.filter((item: any) => item.category === "PTY");
    const sky = skys[0].fcstValue === null ? "null" : skys[0].fcstValue;
    const pty = ptys[0].fcstValue === null ? "null" : ptys[0].fcstValue;
    preSky = sky;
    prePty = pty;
    return { sky, pty };
  } catch (e) {
    console.log(e);
    return { sky: preSky, pty: prePty };
  }
};

const getTemp = async () => {
  try {
    const { baseDate, baseTime } = getDateTime("00");
    const url = `http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getUltraSrtNcst?serviceKey=${WEATHER_KEY}&pageNo=1&numOfRows=4&dataType=JSON&base_date=${baseDate}&base_time=${baseTime}&nx=${WEATHER_NX}&ny=${WEATHER_NY}`;
    const result = await axios.get(url);
    const temp = result.data.response.body.items.item[3].obsrValue;
    preTemp = temp;
    return temp;
  } catch (e) {
    console.log(e);
    return preTemp;
  }
};

const getDateTime = (minute: string): { baseDate: string; baseTime: string } => {
  let newDate = new Date();
  let baseTime = "";
  if (newDate.getHours() === 0 && newDate.getMinutes() < 30) {
    // 0시 30분 이전인 경우 _ 이전날짜 마지막 데이터를 호출
    newDate.setDate(newDate.getDate() - 1);
    baseTime = "2300";
  } else {
    // 0시 30분 이상인 경우 &
    // 30분 이상인경우 현재시간,
    // 30분 이하인경우 이전시간 데이터 호출
    if (newDate.getMinutes() < 30) newDate.setHours(newDate.getHours() - 1);
  }
  const baseDate = newDate.getFullYear() + ("0" + (newDate.getMonth() + 1)).slice(-2) + ("0" + newDate.getDate()).slice(-2);
  baseTime = ("0" + newDate.getHours()).slice(-2) + minute;
  return { baseDate, baseTime };
};
export default getWeather;
