import React, { useEffect, useState } from "react";
import "./GraphViewer.scss";
import { formatDate } from "../utils/Time";
import useInterval from "../hooks/useInterval";
import CustomDatePicker from "./CustomDatePicker";
import CustomLineGraph from "./CustomLineGraph";
import { getLogData } from "../api/aqmanAPI";
import log from "../assets/log.json";

const data = [
  {
    name: new Date("2024-10-10T00:33:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 80,
    GI28AQ000010: 25,
  },
  {
    name: new Date("2024-10-10T00:43:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 90,
    GI28AQ000010: 65,
  },
  {
    name: new Date("2024-10-10T00:53:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 35,
    GI28AQ000010: 30,
  },
  {
    name: new Date("2024-10-10T01:03:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 55,
    GI28AQ000010: 20,
  },
  {
    name: new Date("2024-10-10T01:13:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 40,
    GI28AQ000010: 70,
  },
  {
    name: new Date("2024-10-10T01:23:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 75,
    GI28AQ000010: 10,
  },
  {
    name: new Date("2024-10-10T01:33:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 65,
    GI28AQ000010: 35,
  },
  {
    name: new Date("2024-10-10T01:43:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 80,
    GI28AQ000010: 65,
  },
  {
    name: new Date("2024-10-10T01:53:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 30,
    GI28AQ000010: 25,
  },
  {
    name: new Date("2024-10-10T02:03:39.663").toLocaleString("default", { hour12: false }),
    GI28AQ000006: 100,
    GI28AQ000010: 50,
  },
];
const GraphViewer = () => {
  const indicator = ["PM1", "PM10", "PM2.5", "온도", "습도", "라돈", "CO", "CO2", "TVOC", "HCHO"];
  const unit = ["μg/m³", "μg/m³", "μg/m³", "°C", "%", "Bq/m³", "ppb", "ppm", "ppb", "ppb"];
  const deviceList = ["GI28AQ000006", "GI28AQ000010"];
  const [checked, setChecked] = useState([true, true]);
  const [viewerIndex, setViewerIndex] = useState(0);
  const [ampm, setAmpm] = useState(formatDate("ampm"));
  const [time, setTime] = useState(formatDate("hh:mm"));
  const [dateRange, setDateRange] = useState<any[]>([new Date(), new Date()]);

  const [logData, setLogData] = useState<any | undefined>();

  useInterval(() => {
    setAmpm(formatDate("ampm"));
    setTime(formatDate("hh:mm"));
  }, 1000);
  const handleViewer = (e: any) => {
    setViewerIndex(Number(e.target.id));
  };
  const handleCheck = (e: any) => {
    const preChecked = [...checked];
    const index = Number(e.currentTarget.id);
    preChecked[index] = !preChecked[index];
    setChecked(preChecked);
  };
  // const axiosAqman = async () => {
  //   setLogData({});
  //   const data = await getLogData({ sn: "GI28AQ000010", from: "2024-10-10", to: "2024-10-10" });
  //   if (data) {
  //     const preLogData = { ...logData };
  //     preLogData.GI28AQ000010 = data;
  //     setLogData(preLogData);
  //   }
  //   const data2 = await getLogData({ sn: "GI28AQ000006", from: "2024-10-10", to: "2024-10-10" });
  //   if (data2) {
  //     const preLogData = { ...logData };
  //     preLogData.GI28AQ000006 = data2;
  //     setLogData(preLogData);
  //   }
  // };
  // useEffect(() => {
  //   axiosAqman();
  // }, []);
  // useEffect(() => {
  //   console.log(logData);
  // }, [logData]);
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const date = Math.ceil(Math.abs(dateRange[1] - dateRange[0]) / (1000 * 60 * 60 * 24)) + 1;
      const oneDayCount = 144;
      const dataFormat = Array.from({ length: date * oneDayCount }, () => []);
      const returnData = dataFormat.map((data: any, index: number) => {
        data.dt = new Date(new Date(dateRange[0]).setMinutes(new Date(dateRange[0]).getMinutes() + index * 10)).toLocaleString("default", { hour12: false });
        data.GI28AQ000006_temp = log.data1[index].temp;
        data.GI28AQ000006_humi = log.data1[index].humi;
        data.GI28AQ000006_radon = log.data1[index].radon;
        data.GI28AQ000006_co = log.data1[index].co;
        data.GI28AQ000006_co2 = log.data1[index].co2;
        data.GI28AQ000006_hcho = log.data1[index].hcho;
        data.GI28AQ000006_pm10 = log.data1[index].pm10;
        data.GI28AQ000006_pm2d5 = log.data1[index].pm2d5;
        data.GI28AQ000006_pm1 = log.data1[index].pm1;
        data.GI28AQ000006_tvoc = log.data1[index].tvoc;
        data.GI28AQ000010_temp = log.data2[index].temp;
        data.GI28AQ000010_humi = log.data2[index].humi;
        data.GI28AQ000010_radon = log.data2[index].radon;
        data.GI28AQ000010_co = log.data2[index].co;
        data.GI28AQ000010_co2 = log.data2[index].co2;
        data.GI28AQ000010_hcho = log.data2[index].hcho;
        data.GI28AQ000010_pm10 = log.data2[index].pm10;
        data.GI28AQ000010_pm2d5 = log.data2[index].pm2d5;
        data.GI28AQ000010_pm1 = log.data2[index].pm1;
        data.GI28AQ000010_tvoc = log.data2[index].tvoc;
        return data;
      });
      setLogData(returnData);
    }
  }, [dateRange]);
  return (
    <>
      <div id="graph_wrap">
        <div className="device_list_wrap">
          <div className="name">디바이스 리스트</div>
          <ul>
            {deviceList.map((device: string, index: number) => {
              return (
                <li className="device" key={`${device}_${index}`}>
                  <span>{device}</span>
                  <div id={String(index)} className="checkbox_wrap" onChange={handleCheck}>
                    <input type="checkbox" id={`check_btn_${index}`} checked={checked[index]} onChange={handleCheck} />
                    <label htmlFor={`check_btn_${index}`} className={`color_${index}`}></label>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <main>
          <div className="title_wrap">
            <h1>항목별 그래프</h1>
            <div className="time_wrap">
              <span className="ampm">{ampm}</span>
              <span className="time">{time}</span>
            </div>
          </div>
          <div className="btn_wrap">
            <div className="graph_tap_wrap">
              <button id="0" className={`${viewerIndex === 0 ? "select" : ""}`} onClick={handleViewer}>
                뷰어1
              </button>
              <button id="1" className={`${viewerIndex === 1 ? "select" : ""}`} onClick={handleViewer}>
                뷰어2
              </button>
            </div>
            <div className="indicator_wrap">
              <ul>
                {indicator.map((name: string, index: number) => {
                  return (
                    <li className={String(Math.floor(index / 5) === viewerIndex ? "active" : "")} key={`${name}_${index}`}>
                      {name}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="date_picker_wrap">
              <CustomDatePicker dateRange={dateRange} setDateRange={setDateRange} />
            </div>
            <div className="download_wrap">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect className="circle" width="48" height="48" rx="24" fill="white" />
                <rect x="15" y="30.5264" width="18" height="1.47368" rx="0.736842" fill="#2F5427" />
                <path
                  d="M23.2929 27.7071C23.6834 28.0976 24.3166 28.0976 24.7071 27.7071L31.0711 21.3431C31.4616 20.9526 31.4616 20.3195 31.0711 19.9289C30.6805 19.5384 30.0474 19.5384 29.6569 19.9289L24 25.5858L18.3431 19.9289C17.9526 19.5384 17.3195 19.5384 16.9289 19.9289C16.5384 20.3195 16.5384 20.9526 16.9289 21.3431L23.2929 27.7071ZM25 27L25 15L23 15L23 27L25 27Z"
                  fill="#2F5427"
                />
              </svg>
            </div>
          </div>
          <div className="content_wrap">
            <CustomLineGraph width={512} height={440} name={indicator[viewerIndex === 0 ? 0 : 5]} unit={unit[viewerIndex === 0 ? 0 : 5]} data={logData} checked={checked} />
            <CustomLineGraph width={512} height={440} name={indicator[viewerIndex === 0 ? 1 : 6]} unit={unit[viewerIndex === 0 ? 0 : 5]} data={logData} checked={checked} />
            <CustomLineGraph width={512} height={440} name={indicator[viewerIndex === 0 ? 2 : 7]} unit={unit[viewerIndex === 0 ? 0 : 5]} data={logData} checked={checked} />
            <CustomLineGraph width={776} height={440} name={indicator[viewerIndex === 0 ? 3 : 8]} unit={unit[viewerIndex === 0 ? 0 : 5]} data={logData} checked={checked} />
            <CustomLineGraph width={776} height={440} name={indicator[viewerIndex === 0 ? 4 : 9]} unit={unit[viewerIndex === 0 ? 0 : 5]} data={logData} checked={checked} />
          </div>
        </main>
      </div>
    </>
  );
};

export default GraphViewer;
