import React, { useState } from "react";
import "./Detail.scss";
import GraphViewer from "../components/GraphViewer";
import { ReactComponent as GraphSvg } from "../assets/detail_tap_graph.svg";
import { Link } from "react-router-dom";
const Detail = () => {
  const [tapIndex, setTapIndex] = useState(0);
  const handleTap = (event: any) => {
    setTapIndex(Number(event.target.id));
  };
  const taps = [{ name: "graph", icon: <GraphSvg />, component: <GraphViewer /> }];
  return (
    <>
      <div className="detail_wrap">
        <div className="tap_wrap">
          <Link to="/">
            <img src="/imgs/arrow_left.png" alt="btn_back" />
          </Link>
          <ul>
            {taps.map((tap: { name: string; icon: any; component: any }, index: number) => {
              return (
                <li id={String(index)} className={`${index === tapIndex ? "active" : ""}`} key={`${tap.name}_${index}`} onClick={handleTap}>
                  {tap.icon}
                </li>
              );
            })}
          </ul>
        </div>
        {taps[tapIndex].component}
      </div>
    </>
  );
};

export default Detail;
